<template>
	<main class="index-page">
		<section class="index-main">
			<div class="container-base landing">
				<MainSlider
					v-if="renderSlider"
					class="index-page__slider"
				/>
				<SearchMain class="index-page__search"/>
			</div>
		</section>
	</main>
</template>
<script>
import Updater from '@/mixins/updater.js'
import {useYandexMetrika} from "@/composables";
import qs from "qs";
import axios from "axios";
import {mobileCheck} from "@/helpers";

export default {
	name: 'AuthPage',
	setup()
	{
		const {initYandexMetrika} = useYandexMetrika();

		return {initYandexMetrika};
	},
	data()
	{
		return {
			image: this.$store.state.currentCityImg,
			renderSlider: true,
		}
	},

	components: {
		SearchMain: () => import('@/components/form/SearchMain.vue'),
		FoodTypesSlider: () => import('@/components/block/FoodTypesSlider.vue'),
		MainSlider: () => import('@/components/block/MainPageSlider.vue'),
	},

	created()
	{
		if (!this.$store.state.user) this.$store.commit('openPhoneAuthPopup');
	},

	methods: {
		updateRender()
		{
			this.renderSlider = false;
			setTimeout(() => this.renderSlider = true, 150);

			setTimeout(() =>
			{
				if (window.innerWidth < 567)
				{
					document.documentElement.style.backgroundColor = '#40AE49';
					document.body.style.position = 'fixed';
					document.body.style.top = '-1px';
					document.documentElement.style.height = '1000px';
				} else
				{
					document.body.style.position = 'unset';
					document.body.style.top = 'unset';
					document.documentElement.style.backgroundColor = 'unset';
					document.documentElement.style.height = 'unset';
				}
			}, 150)
		},
		//Если пользователь уже авторизирован - кидаем на профиль
		async checkAuthAndRedirect()
		{
			try
			{
				const params = qs.stringify({'onlyCheckAuth': true});
				const {data} = await axios.post('/auth/isLogged/', params);

				if (data.success) window.location.href = '/application/personal/';
			} catch (error)
			{
				throw new Error(error)
			}
		}
	},
	watch:
		{
			"$store.state.currentRestarauntId"()
			{
				this.$store.dispatch("restaurant/getRestDeliveryTime");
			},
		},
	mixins: [Updater],

	async mounted()
	{
		this.initYandexMetrika();
		if (window.innerWidth < 567)
		{
			document.documentElement.style.backgroundColor = '#40AE49';
			document.body.style.position = 'fixed';
			document.body.style.top = '-1px';
			document.documentElement.style.height = '1000px';
		}

		window.addEventListener('orientationchange', this.updateRender);

		if (mobileCheck('mobileApp')) await this.checkAuthAndRedirect();
	},

	beforeDestroy()
	{
		document.body.style.position = 'unset';
		document.body.style.top = 'unset';
		document.documentElement.style.backgroundColor = 'unset';
		document.documentElement.style.height = 'unset';

		window.removeEventListener('orientationchange', this.updateRender);
	}
}
</script>
<style lang="scss">
.index-page
{
	max-width: 100vw;
	min-height: 100%;
	background: radial-gradient(50% 50% at 50% 50%, #a4ff79 0, rgba(64, 174, 73, 0) 100%) #40ae49;
	padding-bottom: 160px;
	touch-action: unset;

	@media (max-width: 567px)
	{
		position: relative;
		max-height: 100%;
		overflow: hidden;
		-webkit-overflow-scrolling: initial;
		touch-action: none;
		padding-bottom: 0;

		&__search
		{
			margin-top: -16px;
		}
	}
}
</style>